import classes from "./TodoDetail.module.css";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TextField from "@material-ui/core/TextField";
const TodoDetail = (props) => {
  return (
    <div className={classes.TodoDetail}>
      <header style={{marginTop: "4rem"}}>
        <Checkbox
          id={props.id}
          type="checkbox"
          checked={props.checked}
          onClick={props.onClick}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <h2>{props.title}</h2>
      </header>

      <div>Erik Meiland</div>
      <div>{props.date_create}</div>
      <TextField
            id={"date" + props.id}
            type="datetime-local"
            defaultValue=""
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={() => {}}
          />
      <div>Subtasks</div>
      <button>Add a subtask</button>
      {/* <textarea value="Add a note"></textarea> */}
      <div>Chatgeschiedenis</div>
      {/* <textarea value="Add a comment..."></textarea> */}
      <DeleteOutlineIcon id={props.id} onClick={props.onDelete} />
    </div>
  );
};

export default TodoDetail;
