import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import Menu from "./components/Menu/Menu";
import { v4 as uuidv4 } from "uuid";
import Header from "./components/Header/Header";
import classes from "./App.module.css";
import Todo from "./components/Todo/Todo";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Background from "./components/Background/Background";
import AuthContext from "./store/auth-context";
import { Container } from "@material-ui/core";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import {formatDate} from "./components/wunderlist";
import boopSfx from "./Bing-sound.mp3";
import useSound from "use-sound";

import "firebase/database";

import { initializeApp } from "firebase/app";
import { getDatabase, query, ref, set, update, onValue, remove, orderByKey, orderByValue, orderByChild, limitToFirst } from "firebase/database";
import {SortableItem} from './components/SortableItem';

import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';



const firebaseConfig = {
  apiKey: "AIzaSyBcOrV_SLHSItkqwu8mhYGAJ3H_lxIqPUs",
  authDomain: "wunderlist-7a58f.firebaseapp.com",
  databaseURL:
    "https://wunderlist-7a58f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wunderlist-7a58f",
  storageBucket: "wunderlist-7a58f.appspot.com",
  messagingSenderId: "810802041732",
  appId: "1:810802041732:web:f5a7f949aa697a7a6243d1",
};
const firebaseApp = initializeApp(firebaseConfig);

const App = () => {
  const db = getDatabase();
  const [todos, setTodos] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [playSound] = useSound(boopSfx);

  const authCtx = useContext(AuthContext);
  const createInput = useRef();

  const inboxLength = todos.filter(todo => !todo.checked).length;
  const starredLength = todos.filter(todo => todo.starred).length;
  let todayLength = 0;

  // const formatDate = date => {
  //   let transformedDate = new Date(date);
  //   let year = new Date(date).getFullYear();
  //   let month = new Date(date).getMonth();
  //   month = month + 1;
  //   if (month <= 9) month = "0" + month;
  //   let day = new Date(date).getDate();
  //   if (day <= 9) day = "0" + day;
  //   transformedDate = year + "-" + month + "-" + day;
  //   return transformedDate;
  // }
  const now = formatDate(new Date());

  const compareDate = (todo) => {
    let todo_date = formatDate(todo.date || todo.date_create);
    todo_date==now && todayLength++;
  }

  todos.filter(compareDate);

  const loadTodos = () => {
    const todoData = ref(db, "/todos");

    console.log(todoData);
    onValue(todoData, (snapshot) => {
      const data = snapshot.val();
      if(!data) return;
      const transformedData = Object.values(data).map(todo => todo).sort((a,b) => a.order - b.order);
      setTodos(() => transformedData);
    });
  };

  const updateTodoHandler = useCallback((id, fieldname, value) => {
    const updatedTodo = {};
    updatedTodo[`/todos/${id}/date`] = ""+value;
    update(ref(db), updatedTodo);
  }, []);

  const checkTodoHandler = useCallback((id, check) => {
    const updatedTodo = {};
    updatedTodo[`/todos/${id}/checked`] = check;
    console.log(updatedTodo);
    update(ref(db), updatedTodo);
    check && playSound();
  }, []);

  const starTodoHandler = useCallback((id, starred) => {
    const updatedTodo = {};
    updatedTodo[`/todos/${id}/starred`] = starred;
    update(ref(db), updatedTodo);
  },[]);

  const createTodoHandler = useCallback((event) => {
    if (event.key === "Enter") {
      const id = uuidv4();
      const title = event.target.value;
      if (!title) return;
      set(ref(db, "todos/" + id), {
        id: id,
        title: title,
        date_create: "" + new Date(),
        username: "Guest",
        starred: false,
        username: "Guest",
        checked: false,
      }).then(() => {
        createInput.current.value = "";
        createInput.focus();
      })
      .catch((err) => console.log("error:", err));
    }
  }, []);

  const loginHandler = (event) => {
    event.preventDefault();
    setIsLoggedIn(() => true);
  };

  const deleteTodoHandler = (event) => {
    const id = event.target.id;
    const deletedTodo = {};
    deletedTodo[`/todos/${id}/deleted`] = true; 
    update(ref(db), deletedTodo);
    remove(ref(db, "todos/" + id));
  };

  const logoutHandler = (event) => {
    event.preventDefault();
    setIsLoggedIn(() => false);
  };

  useEffect(() => {
    loadTodos(); 
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );


  function handleDragEnd(event) {
    console.log(event);

    const {active, over} = event;
    if (active.id !== over.id) {
      console.log("active.id=",active.id);
      console.log("over.id=",over.id);

      const oldIndex = Object.values(todos).map(item => item.id).indexOf(active.id);
      const newIndex = Object.values(todos).map(item => item.id).indexOf(over.id);
      if(!oldIndex || !newIndex) return;
      const movedTodo = {};
      console.log(oldIndex);
      console.log(newIndex);
      movedTodo[`/todos/${active.id}/order`] = newIndex;
      movedTodo[`/todos/${over.id}/order`] = oldIndex;
      update(ref(db), movedTodo);
      // alert("moved!");
      // return arrayMove(items, oldIndex, newIndex);

      // setTodos((items) => {
      //   const oldIndex = Object.values(items).map(item => item.id).indexOf(active.id);
      //   const newIndex = Object.values(items).map(item => item.id).indexOf(over.id)
      //   const movedTodo = {};
      //   movedTodo[`/order`] = newIndex
      //   update(ref(db), movedTodo);
      //   return arrayMove(items, oldIndex, newIndex);
      // });
    }

    // const {active, over} = event;
    
    // if (active.id !== over.id) {
    //   setItems((items) => {
    //     const oldIndex = items.indexOf(active.id);
    //     const newIndex = items.indexOf(over.id);
        
    //     return arrayMove(items, oldIndex, newIndex);
    //   });
    // }
  }

  const [items, setItems] = useState(['1', '2', '3']);

   return (
    <Container>
      <div className={classes.App}>
        <AuthContext.Provider
          value={{
            isLoggedIn: isLoggedIn,
            onLogout: logoutHandler,
            onLogin: loginHandler,
          }}
        >
          <Background />
          <Header />

          <Grid container spacing={5} style={{ marginTop: "4rem" }}>
            <Grid item md={3}>
              <Menu inboxLength={inboxLength} starredLength={starredLength} todayLength={todayLength} />
            </Grid>
            <Grid item md={9}>
              <ErrorBoundary>
                <TextField
                  className={classes.AppCreate}
                  inputRef={createInput}
                  fullWidth
                  type="text"
                  placeholder="Add a to-do..."
                  onKeyDown={createTodoHandler}
                />
              </ErrorBoundary>
              {!todos.length && <div>Everything done!</div>}


              <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
      {items.map(id => <SortableItem key={id} id={id} />)}

      </SortableContext>

      <SortableContext 
        items={todos}
        strategy={verticalListSortingStrategy}
      >
              {todos.map((todo) => {
                if (!todo.checked && !todo.deleted) {
                  return (
                    <Todo
                      order={todo.order}
                      checked={todo.checked}
                      key={todo.id}
                      id={todo.id}
                      title={todo.title}
                      onCheck={checkTodoHandler}
                      onUpdate={updateTodoHandler}
                      onDelete={deleteTodoHandler}
                      onToggleStar={starTodoHandler}
                      starred={todo.starred}
                      date={todo.date}
                      date_create={todo.date_create}
                      username={todo.username}
                    />
                  );
                } else {
                  return false;
                }
              })}
      </SortableContext>
    </DndContext>

              <h1>Complete items</h1>
              {todos.map((todo) => {
                if (todo.checked === true) {
                  return (
                    <Todo
                      checked={todo.checked}
                      key={todo.id}
                      id={todo.id}
                      title={todo.title}
                      onCheck={checkTodoHandler}
                      onUpdate={updateTodoHandler}
                      onDelete={deleteTodoHandler}
                      onToggleStar={starTodoHandler}
                      starred={todo.starred}
                      date={todo.date}
                      date_create={todo.date_create}
                      username={todo.username}
                    />
                  );
                } else {
                  return false;
                }
              })}

              {/* <pre>{JSON.stringify(todos)}</pre>
              <pre>{JSON.stringify(Object.keys(todos))}</pre> */}
            </Grid>
          </Grid>
        </AuthContext.Provider>
      </div>
    </Container>
  );
};

export default App;
