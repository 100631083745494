import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import StarsIcon from "@material-ui/icons/Stars";
import TodayIcon from "@material-ui/icons/Today";
import InboxIcon from "@material-ui/icons/Inbox";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const Menu = props => {
    return (
        <ButtonGroup
        orientation="vertical"
        style={{ backgroundColor: "white", width: "100%" }}
      >
        <Button>
          <InboxIcon />
           Inbox <small>({props.inboxLength})</small>
        </Button>
        <Button>
          <PersonAddIcon />
          Assigned to me
        </Button>
        <Button>
          <StarsIcon />
          Starred <small>({props.starredLength})</small>
        </Button>
        <Button>
          <TodayIcon />
          Today <small>({props.todayLength})</small>
        </Button>
        <Button>
          <ViewWeekIcon />
          Week
        </Button>
      </ButtonGroup>
    )
}

export default Menu;