export const formatDate = date => {
    let transformedDate = new Date(date);
    let year = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    month = month + 1;
    if (month <= 9) month = "0" + month;
    let day = new Date(date).getDate();
    if (day <= 9) day = "0" + day;
    transformedDate = year + "-" + month + "-" + day;
    return transformedDate;
  }