import React, { useRef, useContext } from "react";
import AuthContext from "../../store/auth-context";

const Login = (props) => {
  const authCtx = useContext(AuthContext);
  var username = useRef();

  return (
    <React.Fragment>
      {!authCtx.isLoggedIn ? (
        <form>
          <label>Username</label>
          <input ref={username} type="text" />
          <label>Password</label>
          <input type="password" />
          <button onClick={authCtx.onLogin}>Inloggen</button>
        </form>
      ) : (
        <button onClick={authCtx.onLogout}>Loguit!</button>
      )}
    </React.Fragment>
  );
};

export default Login;
