import React from "react";
import ReactDOM from "react-dom";

import classes from "./Background.module.css";

const MyBG = () => {
    return (
        <div className={classes.Background}>
            
        </div> 
        )
}

const Background = () => {  

     return (
         <React.Fragment>
         {ReactDOM.createPortal(<MyBG />, document.getElementById("bg"))}
         </React.Fragment>
     )

}

export default Background;