import React, { useState } from "react";
import classes from "./Todo.module.css";
import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Grid from "@material-ui/core/Grid";
// import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ChatIcon from "@material-ui/icons/Chat";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TodoDetail from "./TodoDetail";
import { formatDate } from "../wunderlist";
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const Todo = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [showDetail, setShowDetail] = useState(false);

  const toggleDetailHandler = () => {
    setShowDetail((prevState) => !prevState);
  };

  const dateChangeHandler = (event) => {
    // console.log(event.target.type);
    const fieldname = event.target.type;
    props.onUpdate(props.id, fieldname, event.target.value);
  };

  const date_todo = formatDate(props.date || props.date_create)
  
  const checkboxHandler = event => {
    const checked = event.target.checked;
      props.onCheck(event.target.id, checked);
      return () => {};
  };
  
  const toggleStarHandler = (id, starred) => {
    props.onToggleStar(id, starred);
  };

  let expired = false;
  const now = new Date();
  now.setHours(0,0,0,0);
  if(new Date(props.date) < now) {
    expired = true;
  }

  return (
    <>
      <Grid ref={setNodeRef} style={style} {...attributes} {...listeners} container className={`${classes.Todo} ${expired && classes.Expired}`} expired={""+expired}>
        <Grid item md={6}>
          <Checkbox
            id={props.id}
            type="checkbox"
            checked={props.checked}
            onClick={checkboxHandler.bind(this)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          {/* <span>id: {props.id}</span> */}
          <span
            onClick={props.id && toggleDetailHandler}
            style={{ textDecoration: props.checked && "line-through" }}
          >
            {props.title ? props.order+": "+props.title : "Geen tekst"}
          </span>
        </Grid>
        <Grid item md={1}>
          <ChatIcon />
        </Grid>
        <Grid
          item
          md={3}
          style={{
            textAlign: "right",
          }}
        >
          <TextField
            id={"date" + props.id}
            type="date"
            defaultValue={date_todo}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={dateChangeHandler}
          />
        </Grid>
        {/* <Grid item md={2}>
          {props.username}
        </Grid> */}
        <Grid item md={1}>
          <AccountCircleIcon />
        </Grid>

        <Grid item md={1} className={classes.Star} id={props.id}>
          {props.starred ? 
            <div id={props.id} onClick={toggleStarHandler.bind(this, props.id, false)}><StarIcon /></div>
           : 
            <div id={props.id} onClick={toggleStarHandler.bind(this, props.id, true)}><StarBorderIcon /></div>

            }
        </Grid>
      </Grid>
      {showDetail && <TodoDetail onDelete={props.onDelete} onClick={checkboxHandler.bind(this)} {...props} />}
    </>
  );
};

export default Todo;
