import classes from "./Header.module.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Login from "../../components/Login/Login";
import Grid from "@material-ui/core/Grid";

const Header = (props) => {
  return (
    <AppBar position="fixed" className={classes.AppHeader}>
      <Toolbar>
        <Grid container>
          <Grid item md={8}>
            <h2>AuToDota</h2>
          </Grid>
          <Grid item md={4}>
            <Login />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
